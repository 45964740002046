@import '../../style/themes/index';
@import '../../style/mixins/index';

@tooltip-prefix-cls: ~'@{ant-prefix}-tooltip';

// Base class
.@{tooltip-prefix-cls} {
  .reset-component;

  position: absolute;
  z-index: @zindex-tooltip;
  display: block;
  max-width: @tooltip-max-width;
  visibility: visible;

  &-hidden {
    display: none;
  }

  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding-bottom: @tooltip-distance;
  }
  &-placement-right,
  &-placement-rightTop,
  &-placement-rightBottom {
    padding-left: @tooltip-distance;
  }
  &-placement-bottom,
  &-placement-bottomLeft,
  &-placement-bottomRight {
    padding-top: @tooltip-distance;
  }
  &-placement-left,
  &-placement-leftTop,
  &-placement-leftBottom {
    padding-right: @tooltip-distance;
  }

  // Wrapper for the tooltip content
  &-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: @tooltip-color;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: @tooltip-bg;
    border-radius: @border-radius-base;
    box-shadow: @box-shadow-base;
  }

  // Arrows
  &-arrow {
    position: absolute;
    display: block;
    width: sqrt(@tooltip-arrow-width * @tooltip-arrow-width * 2);
    height: sqrt(@tooltip-arrow-width * @tooltip-arrow-width * 2);
    background: transparent;
    border-style: solid;
    border-width: sqrt(@tooltip-arrow-width * @tooltip-arrow-width * 2) / 2;
    transform: rotate(45deg);
  }

  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    bottom: @tooltip-distance - @tooltip-arrow-width + 2.2px;
    border-top-color: transparent;
    border-right-color: tint(fadein(@tooltip-bg, 100%), 25%); // make it not transparent
    border-bottom-color: tint(fadein(@tooltip-bg, 100%), 25%); // make it not transparent
    border-left-color: transparent;
    box-shadow: 3px 3px 7px fade(@black, 7%);
  }

  &-placement-top &-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  &-placement-topLeft &-arrow {
    left: 16px;
  }

  &-placement-topRight &-arrow {
    right: 16px;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: @tooltip-distance - @tooltip-arrow-width + 2px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: tint(fadein(@tooltip-bg, 100%), 25%);
    border-left-color: tint(fadein(@tooltip-bg, 100%), 25%);
    box-shadow: -3px 3px 7px fade(@black, 7%);
  }

  &-placement-right &-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  &-placement-rightTop &-arrow {
    top: 8px;
  }

  &-placement-rightBottom &-arrow {
    bottom: 8px;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: @tooltip-distance - @tooltip-arrow-width + 2px;
    border-top-color: tint(fadein(@tooltip-bg, 100%), 25%);
    border-right-color: tint(fadein(@tooltip-bg, 100%), 25%);
    border-bottom-color: transparent;
    border-left-color: transparent;
    box-shadow: 3px -3px 7px fade(@black, 7%);
  }

  &-placement-left &-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  &-placement-leftTop &-arrow {
    top: 8px;
  }

  &-placement-leftBottom &-arrow {
    bottom: 8px;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: @tooltip-distance - @tooltip-arrow-width + 2px;
    border-top-color: tint(fadein(@tooltip-bg, 100%), 25%);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: tint(fadein(@tooltip-bg, 100%), 25%);
    box-shadow: -2px -2px 5px fade(@black, 6%);
  }

  &-placement-bottom &-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  &-placement-bottomLeft &-arrow {
    left: 16px;
  }

  &-placement-bottomRight &-arrow {
    right: 16px;
  }
}
